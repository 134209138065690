import { categories } from '@/signals/categories';
import classNames from 'classnames';
import { Link } from 'preact-router';

import s from './index.module.css';
import { brandsWithproducts } from '@/signals/brands';

export const ShopSubheader = ({ section, id }: { section?: string; id?: string }) => {
  if (!section) return null;

  if (section === 'brands') {
    return (
      <div className="bg-body-secondary p-3 border-bottom">
        <div className="container">
          <div class={s.ShopSubheader}>
            {brandsWithproducts.value.map((b) => (
              <Link
                class={classNames('btn', {
                  'btn-outline-secondary': b.id !== id,
                  'btn-secondary': b.id === id,
                })}
                href={`/shop/brands/${b.id}?brands=${b.id}`}
              >
                {b.value}
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }

  const type = { types: 'type', components: 'component', problems: 'problem' }[section];
  const _categories = categories.value.filter((c) => c.type === type);

  if (!type) return null;

  return (
    <div className="bg-body-secondary p-3 border-bottom">
      <div className="container">
        <div class={s.ShopSubheader}>
          {_categories.map((b) => (
            <Link
              class={classNames('btn', {
                'btn-outline-secondary': b.id !== id,
                'btn-secondary': b.id === id,
              })}
              href={`/shop/${section}/${b.id}?${section}=${b.id}`}
            >
              {b.value}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
