import { collection, onSnapshot } from 'firebase/firestore';

import { firestore } from '@/firebase';
import { Category } from '@/types';
import { COMPANY_ID } from '@/constants';

export const subscribeToShopCategories = (callback: (categories: Category[]) => void) => {
  const q = collection(firestore, 'c', COMPANY_ID, 'categories');

  return onSnapshot(q, (querySnapshot) => {
    const r: Category[] = [];
    querySnapshot.forEach((doc) => r.push({ ...doc.data(), id: doc.id } as Category));

    callback(r);
  });
};
