import { isCartVisible } from '@/signals/cart';
import { mobileFiltersVisible } from '@/signals/ui';

export const ShopHeaderMobile = ({ section }: { section?: string }) => {
  return (
    <>
      <div className="bg-light p-3 border-bottom d-flex align-items-center">
        <b style={{ fontSize: 18 }}>{(section === 'cart' && 'Корзина') || 'Магазин'}</b>
        <button class="ms-auto btn btn-sm btn-outline-secondary" onClick={() => (mobileFiltersVisible.value = true)}>
          🔎
        </button>
        <button class="ms-2 btn btn-sm btn-outline-secondary" onClick={() => (isCartVisible.value = true)}>
          🛒
        </button>
      </div>
    </>
  );
};
