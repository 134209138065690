.FilteredProducts__filter {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  margin: 0.5rem 0 0;
}

.FilteredProducts__productsGrid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .FilteredProducts__productsGrid {
    grid-template-columns: 1fr 1fr;
  }
}

.productCard {
  cursor: pointer;
  transition: all 0.15s ease;
}

@media (min-width: 1024px) {
  .productCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  }
}
