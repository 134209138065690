.RangeSlider {
  width: 100%;
  height: 20px;
  margin: 1rem 0;
  position: relative;
  overflow: hidden;

  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;

    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin: 0;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    height: 0;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;

    /*custom styles*/
    background-color: var(--bs-green);
    position: relative;
    z-index: 2;
    pointer-events: all;
    height: 20px;
    width: 20px;
    margin-top: -10px;
    border-radius: 50%;
  }

  /******** Firefox styles ********/
  /* slider track */
  input[type='range']::-moz-range-track {
    height: 0;
  }

  /* slider thumb */
  input[type='range']::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/

    /*custom styles*/
    background-color: var(--bs-green);
    position: relative;
    z-index: 2;
    pointer-events: all;
    height: 20px;
    width: 20px;
    margin-top: -10px;
    border-radius: 50%;
  }
}

.RangeSlider__trackBackground,
.RangeSlider__track {
  height: 6px;
  top: 7px;
  bottom: 7px;
  position: absolute;
  pointer-events: none;
}

.RangeSlider__track {
  background-color: var(--bs-green);
  z-index: 1;
}

.RangeSlider__trackBackground {
  background-color: #eeeeee;
  z-index: 0;
}
