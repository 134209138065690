import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { firestore } from '@/firebase';
import { COMPANY_ID } from '@/constants';
import { Product } from 'cosdb-types';

export const subscribeToShopProducts = (callback: (products: Product[]) => void) => {
  const q = query(
    collection(firestore, 'c', COMPANY_ID, 'products'),
    where('deleted', '==', false),
    where('showInShop', '==', true),
  );

  return onSnapshot(q, (querySnapshot) => {
    const r: Product[] = [];
    querySnapshot.forEach((doc) => r.push({ ...doc.data(), id: doc.id } as Product));

    callback(r);
  });
};
