import { signal } from '@preact/signals';

const tabletMatcher = window.matchMedia('(min-width: 768px)');
const desktopMatcher = window.matchMedia('(min-width: 1024px)');

const onChange = () => {
  viewport.value = desktopMatcher.matches ? 'desktop' : tabletMatcher.matches ? 'tablet' : 'mobile';
};

export const viewport = signal<'mobile' | 'tablet' | 'desktop'>('mobile');

onChange();

tabletMatcher.onchange = onChange;
desktopMatcher.onchange = onChange;
