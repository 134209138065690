.Shop {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: min-content 1fr;
}

.Shop:has(.ShopSubheader) {
  grid-template-rows: min-content min-content 1fr;
}

.Shop__content {
  scrollbar-gutter: stable both-edges;
  overflow: auto;
}

.ShopSubheader,
.ShopHeader {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}
