import classNames from 'classnames';
import { Product } from 'cosdb-types';
import s from './product-prices.module.css';

export const ProductPrices = ({ product, className }: { product: Product; className?: string }) => {
  if (product.salePrice) {
    return (
      <div class={classNames(s.ProductPrices, 'm-0', className)}>
        <span>{product.salePrice}₴</span>
        <span class={s.ProductPrices__sale}>{product.price}₴</span>
      </div>
    );
  }

  return (
    <div class={classNames(s.ProductPrices, 'm-0', className)}>
      <span>{product.price}₴</span>
    </div>
  );
};
