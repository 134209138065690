import { useEffect, useState } from 'preact/hooks';

import s from './RangeSlider.module.css';

export const RangeSlider = ({
  min,
  max,
  defaultMin,
  defaultMax,
  onChange,
}: {
  min: number;
  max: number;
  defaultMin?: number;
  defaultMax?: number;
  onChange: ({ min, max }: { min: number; max: number }) => void;
}) => {
  const [val1, setVal1] = useState<number | null>(null);
  const [val2, setVal2] = useState<number | null>(null);

  useEffect(() => {
    setVal1(Math.max(defaultMin || -Infinity, min));
    setVal2(Math.min(defaultMax || Infinity, max));
  }, [min, max]);

  if (val1 === null || val2 === null) return null;

  const onInput = (setter: (arg: number) => void) => (e: Event) => {
    const target = e.target as HTMLInputElement;

    if (!target?.value) return;

    setter(parseInt(target.value, 10));
  };

  const onThrottledChange = () => {
    onChange({ min: Math.min(val1, val2), max: Math.max(val1, val2) });
  };

  return (
    <>
      <div class={s.RangeSlider}>
        <input type="range" min={min} max={max} value={val1} onChange={onThrottledChange} onInput={onInput(setVal1)} />
        <input type="range" min={min} max={max} value={val2} onChange={onThrottledChange} onInput={onInput(setVal2)} />
        <div
          class={s.RangeSlider__track}
          style={{
            left: `${((Math.min(val1, val2) - min) / (max - min)) * 100}%`,
            right: `${100 - ((Math.max(val1, val2) - min) / (max - min)) * 100}%`,
          }}
        />
        <div class={s.RangeSlider__trackBackground} style={{ left: 0, right: 0 }} />
      </div>
      <div class="d-flex justify-content-between">
        <small class="text-success">Від {Math.min(val1, val2)} грн.</small>
        <small class="text-success">до {Math.max(val1, val2)} грн.</small>
      </div>
    </>
  );
};
