import { brandById } from '@/signals/brands';
import { COMPANY_ID } from '@/constants';
import { viewport } from '@/signals/viewport';

export const ShopBrandDetails = ({ brand: _brand }: { brand: string }) => {
  if (viewport.value === 'mobile') return null;

  const brand = brandById(_brand);

  if (!brand) return null;

  return (
    <div className="row align-items-center pb-4">
      <div className="col-4 d-flex align-items-center justify-content-center">
        {brand.thumbnail && (
          <img
            src={`https://storage.googleapis.com/cosdb2-thumbnails/c/${COMPANY_ID}/brands/${(brand.thumbnail || '').split('.')[0]}_640x640.webp`}
            class="img-thumbnail"
          />
        )}
      </div>
      <div className="col-8">
        <h1>{brand?.value}</h1>
        <p>{brand?.description}</p>
      </div>
    </div>
  );
};
