import { render } from 'preact';
import Router, { Route } from 'preact-router';
import AsyncRoute from 'preact-async-route';

import { Root } from './pages/root';
import { Shop } from './pages/shop';

const App = () => {
  return (
    <Router>
      <Route path="/" component={Root} />
      <Route path="/shop/:section?/:id?" component={Shop} />
      <AsyncRoute path="/admin/:page*" getComponent={() => import('./pages/admin').then((module) => module.Admin)} />
    </Router>
  );
};

render(<App />, document.body);
