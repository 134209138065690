import { brandNameById } from '@/signals/brands';
import { addQueryParam } from '@/utils';
import classNames from 'classnames';
import { Product } from 'cosdb-types';
import { useRouter } from 'preact-router';

import s from './product-name.module.css';

export const ProductName = ({
  product,
  size,
  clickable = false,
}: {
  clickable?: boolean;
  product: Product;
  size: 'h5' | 'h4' | 'h6';
}) => {
  const [route, navigate] = useRouter();

  return (
    <div class={classNames('mb-3', size)}>
      <div class="mb-1" style={{ fontSize: '75%', fontWeight: 'normal' }}>
        {brandNameById(product.brandId)}
      </div>
      <div
        class={classNames({ [s.clickable]: clickable })}
        onClick={clickable ? () => navigate(addQueryParam(route.url, 'productId', product.id)) : undefined}
      >
        {product.name}
      </div>
    </div>
  );
};
