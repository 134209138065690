import { Product } from 'cosdb-types';
import { ProductPrices } from './product-prices';
import { ComponentChildren } from 'preact';

export const ProductPricesWithCta = ({ product, children }: { product: Product; children?: ComponentChildren }) => {
  return (
    <div class="d-flex justify-content-between w-100 align-items-center">
      <ProductPrices product={product} className="h4" />
      {children}
    </div>
  );
};
