import { categories } from '@/signals/categories';
import { getProductById } from '@/signals/products';

import s from './product-card.module.css';
import { COMPANY_ID } from '@/constants';
import { addToCart, cart } from '@/signals/cart';
import { useRouter } from 'preact-router';
import { removeQueryParam } from '@/utils';
import { ProductStatuses } from '@/components/product-statuses';
import { ProductPricesWithCta } from '@/components/product-prices-with-cta';
import { ProductName } from '@/components/product-name';

export const ProductCard = () => {
  const [route, navigate] = useRouter();
  const product = getProductById(route.matches?.productId || '');

  if (!product) return null;

  const onClose = () => {
    navigate(removeQueryParam(route.url, 'productId'));
  };

  return (
    <>
      <div class="modal-backdrop show" style={{ display: 'block', zIndex: 999 }} />
      <div class="modal" style={{ display: 'block', zIndex: 999 }} onClick={onClose}>
        <div class="modal-dialog modal-dialog-centered" onClick={(e) => e.stopPropagation()} style={{ maxWidth: 600 }}>
          <div class="modal-content">
            <button
              style={{ position: 'absolute', right: '1rem', top: '1rem', zIndex: 999 }}
              type="button"
              class="btn-close"
              onClick={onClose}
            />
            <div class="modal-body">
              <ProductStatuses product={product} />
              {product.thumbnail && (
                <img
                  style={{
                    aspectRatio: '2 / 1',
                    objectFit: 'contain',
                    backgroundColor: '#ffffff',
                    width: '100%',
                    padding: '2rem',
                  }}
                  src={`https://storage.googleapis.com/cosdb2-thumbnails/c/${COMPANY_ID}/products/${(product.thumbnail || '').split('.')[0]}_640x640.webp`}
                />
              )}
              <div class={s.heading}>
                <ProductName product={product} size="h4" />

                <hr />

                <ProductPricesWithCta product={product}>
                  {cart.value.find((id) => id === product.id) ? (
                    <button class="btn btn-outline-primary" disabled>
                      Товар в кошику
                    </button>
                  ) : (
                    <button
                      class="btn btn-primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        addToCart(product);
                      }}
                    >
                      Додати в кошик
                    </button>
                  )}
                </ProductPricesWithCta>
              </div>
              <hr />
              {product.description.split('\n\n').map((i) => (
                <p>{i}</p>
              ))}
              <hr />
              {(product.categoryIds?.length || 0) > 0 && (
                <div class={s.ProductCard__tags}>
                  {product.categoryIds!.map((id) => {
                    const category = categories.value.find((c) => c.id === id);
                    if (!category) return null;
                    return (
                      <button class="btn btn-sm btn-outline-secondary" disabled>
                        {category.value}
                      </button>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
