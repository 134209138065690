import { effect, signal } from '@preact/signals';
import { Product } from 'cosdb-types';

const persistedString: string = localStorage.getItem('cart') || '[]';
const persisted = JSON.parse(persistedString) as string[];

export const cart = signal<Product['id'][]>(persisted);

effect(() => localStorage.setItem('cart', JSON.stringify(cart.value)));

export const addToCart = (p: Product) => {
  cart.value = [...cart.value, p.id];
};

export const isCartVisible = signal<boolean>(false);
