import { collection, onSnapshot } from 'firebase/firestore';
import { firestore } from '@/firebase';
import { COMPANY_ID } from '@/constants';
import { Brand } from 'cosdb-types';

export const subscribeToShopBrands = (callback: (brands: Brand[]) => void) => {
  const q = collection(firestore, 'c', COMPANY_ID, 'brands');

  return onSnapshot(q, (querySnapshot) => {
    const r: Brand[] = [];
    querySnapshot.forEach((doc) => r.push({ ...doc.data(), id: doc.id } as Brand));

    callback(r);
  });
};
