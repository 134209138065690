.ProductPrices {
  position: relative;
  display: inline-flex;
  align-items: flex-end;
  gap: 0.5rem;
}

.ProductPrices__sale {
  opacity: 0.45;
  font-size: 75%;
  text-decoration: line-through;
}
