import { ProductPricesWithCta } from '@/components/product-prices-with-cta';
import { COMPANY_ID } from '@/constants';
import { cart, isCartVisible } from '@/signals/cart';
import { getProductById } from '@/signals/products';
import { useState } from 'preact/hooks';
import { ProductName } from '@/components/product-name';

export const ShopCart = () => {
  const [performingRequest, setPerformingRequest] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  if (!isCartVisible.value) return null;

  const onSubmit = (e: Event) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);

    setPerformingRequest(true);
    fetch('https://us-central1-cosdb2-c2d7c.cloudfunctions.net/expressApp/create-order', {
      method: 'POST',
      body: JSON.stringify({
        products: cart.value.map((id) => getProductById(id)).map((p) => ({ id: p!.id, amount: 1, price: p!.price })),
        contactInfo: formData.get('contact-info'),
        name: formData.get('name'),
        shippingDetails: formData.get('shipping-details'),
      }),
      headers: { 'content-type': 'application/json' },
    })
      .catch(() =>
        alert('Щось сталося, замовлення не створено. Спробуйте пізніше та/або напишіть @usalko.cosmetology.'),
      )
      .finally(() => {
        setSuccessModal(true);
        setPerformingRequest(false);
      });
  };

  const onShareList = () => {
    window.open(`/shop?productIds=${cart.value.join(',')}`, '_blank');
  };

  const formDisabled = performingRequest || !cart.value.length;

  if (successModal) {
    const onClose = () => {
      setSuccessModal(false);
      isCartVisible.value = false;
      cart.value = [];
    };

    return (
      <>
        <div class="modal-backdrop show" style={{ display: 'block', zIndex: 999 }} />
        <div class="modal" style={{ display: 'block', zIndex: 999 }} onClick={onClose}>
          <div class="modal-dialog modal-dialog-centered" onClick={(e) => e.stopPropagation()}>
            <div class="modal-content">
              <div class="modal-body">
                <div class="card text-center">
                  <div class="card-body">
                    <h5 class="card-title">Замовлення створене!</h5>
                    <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                    <button onClick={onClose} class="btn btn-primary">
                      Продовжити
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div class="modal-backdrop show" style={{ display: 'block', zIndex: 998 }} />
      <div class="modal" style={{ display: 'block', zIndex: 998 }} onClick={() => (isCartVisible.value = false)}>
        <div class="modal-dialog modal-dialog-centered" onClick={(e) => e.stopPropagation()}>
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Корзина</h4>
              <button type="button" class="btn-close" onClick={() => (isCartVisible.value = false)} />
            </div>
            <div class="modal-body">
              <h5 class="mb-3">Замовлення:</h5>
              <div class="list-group mb-4">
                {cart.value.length === 0 && <i>Нічого не обрано.</i>}
                {cart.value.map((id) => {
                  const p = getProductById(id);

                  if (!p) return null;

                  return (
                    <div class="card mb-1">
                      <div class="row g-0 align-items-center">
                        <div class="col-md-3 p-2 text-center">
                          {p.thumbnail && (
                            <img
                              style={{ maxHeight: 100 }}
                              src={`https://storage.googleapis.com/cosdb2-thumbnails/c/${COMPANY_ID}/products/${(p.thumbnail || '').split('.')[0]}_480x480.webp`}
                              class="img-fluid rounded-start"
                              alt={p.name}
                            />
                          )}
                        </div>
                        <div class="col-md-9">
                          <div class="card-body">
                            <ProductName product={p} size="h6" clickable />
                            <ProductPricesWithCta product={p}>
                              <button
                                class="btn btn-sm btn-outline-danger"
                                onClick={() => {
                                  cart.value = cart.value.filter((i) => i !== id);
                                }}
                              >
                                Видалити
                              </button>
                            </ProductPricesWithCta>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {cart.value.length !== 0 && (
                  <div class="mt-2">
                    <button class="btn btn-outline-success" onClick={onShareList}>
                      Поділитися набором
                    </button>
                  </div>
                )}
              </div>
              <h5>Оформити замовлення:</h5>
              <form onSubmit={onSubmit}>
                <div class="mb-2">
                  <label for="name" class="form-label">
                    Ім'я
                  </label>
                  <input required type="text" class="form-control" name="name" id="name" disabled={formDisabled} />
                </div>
                <div class="mb-3">
                  <label for="contact-info" class="form-label">
                    Номер телефону (чи лінка instagram)
                  </label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    name="contact-info"
                    id="contact-info"
                    disabled={formDisabled}
                  />
                  <div class="form-text">Використовується виключно для зворотнього зв'язку.</div>
                </div>
                <div class="mb-3">
                  <label for="shipping-details" class="form-label">
                    Інформація для доставки
                  </label>
                  <textarea
                    required
                    class="form-control"
                    rows={3}
                    name="shipping-details"
                    id="shipping-details"
                    disabled={formDisabled}
                  />
                  <div class="form-text">
                    Відділення НП, номер телефону отримувача, повне ім'я отримувача. При самовивозі з [адреса] вкажіть у
                    полі "самовивіз".
                  </div>
                </div>
                <button type="submit" class="btn btn-primary" disabled={formDisabled}>
                  Створити замовлення
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
