import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBUGMjWXDKs5FJXy0UeENLQyBumVQjkMr0',
  authDomain: 'cosdb2-c2d7c.firebaseapp.com',
  projectId: 'cosdb2-c2d7c',
  storageBucket: 'cosdb2-c2d7c.appspot.com',
  messagingSenderId: '329506444047',
  appId: '1:329506444047:web:e8f69e0e255213be4de47a',
  measurementId: 'G-385WSTWYZJ',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app, 'gs://cosdb2-c2d7c.appspot.com');
export const thumbsBucket = getStorage(app, 'gs://cosdb2-thumbnails');
export const base = 'https://firebasestorage.googleapis.com/v0/b/cosdb2-c2d7c.appspot.com/o/c';
