import { collection, onSnapshot } from 'firebase/firestore';
import { firestore } from '@/firebase';
import { COMPANY_ID } from '@/constants';
import { Order } from 'cosdb-types';

export const subscribeToShopOrders = (callback: (orders: Order[]) => void) => {
  const q = collection(firestore, 'c', COMPANY_ID, 'orders');

  return onSnapshot(q, (querySnapshot) => {
    const r: Order[] = [];
    querySnapshot.forEach((doc) => r.push({ ...doc.data(), id: doc.id } as Order));

    callback(r);
  });
};
