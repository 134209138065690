export const addQueryParam = (url: string, key: string, value: string) => {
  const [pathname, search] = url.split('?');
  const params = new URLSearchParams(search);
  params.set(key, value);

  return `${pathname}?${params}`;
};

export const addValueToArrayQueryParam = (url: string, key: string, value: string) => {
  const [pathname, search] = url.split('?');
  const params = new URLSearchParams(search);
  const currentValues = params.get(key)?.split(',') || [];
  const newValues = [...currentValues, value];

  params.set(key, newValues.join(','));

  return `${pathname}?${params}`;
};

export const removeQueryParam = (url: string, key: string) => {
  const [pathname, search] = url.split('?');
  const params = new URLSearchParams(search);
  params.delete(key);

  return pathname + (params.toString() ? '?' + params.toString() : '');
};

export const removeValueFromArrayQueryParam = (url: string, key: string, value: string) => {
  const [pathname, search] = url.split('?');

  if (!search) return url;

  const params = new URLSearchParams(search);

  if (!params.has(key)) return url;

  const paramValues = params.get(key)?.split(',') || [];
  const updatedValues = paramValues.filter((val) => val !== value);

  if (updatedValues.length === 0) {
    params.delete(key);
  } else {
    params.set(key, updatedValues.join(','));
  }

  return pathname + (params.toString() ? '?' + params.toString() : '');
};
