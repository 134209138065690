import { computed, signal } from '@preact/signals';
import { shopProducts } from './products';
import { Brand } from 'cosdb-types';

export const brands = signal<Brand[]>([]);

export const brandById = (brandId?: string) => brands.value.find((b) => b.id === brandId);

export const brandNameById = (brandId?: string) => brandById(brandId)?.value;

export const brandsWithproducts = computed(() =>
  brands.value.filter((b) => shopProducts.value.some((p) => p.brandId === b.id)),
);
