import classNames from 'classnames';
import { Link } from 'preact-router';

import s from './index.module.css';
import { cart, isCartVisible } from '@/signals/cart';

export const ShopHeaderDesktop = ({ section }: { section?: string }) => {
  return (
    <div className="bg-light p-3 border-bottom">
      <div className="container">
        <div class={s.ShopHeader}>
          <Link
            class={classNames('btn', {
              'btn-outline-primary': !!section,
              'btn-primary': !section,
            })}
            href={'/shop'}
          >
            🏠
          </Link>
          <Link
            class={classNames('btn', {
              'btn-outline-primary': section !== 'brands',
              'btn-primary': section === 'brands',
            })}
            href={'/shop/brands'}
          >
            За брендом
          </Link>
          <Link
            class={classNames('btn', {
              'btn-outline-primary': section !== 'types',
              'btn-primary': section === 'types',
            })}
            href={'/shop/types'}
          >
            За типом догляду
          </Link>
          <Link
            class={classNames('btn', {
              'btn-outline-primary': section !== 'problems',
              'btn-primary': section === 'problems',
            })}
            href={'/shop/problems'}
          >
            За проблемою
          </Link>
          <Link
            class={classNames('btn', {
              'btn-outline-primary': section !== 'components',
              'btn-primary': section === 'components',
            })}
            href={'/shop/components'}
          >
            За компонентами
          </Link>
          {(cart.value.length && (
            <button
              class={classNames('btn ms-auto', {
                'btn-outline-success': section !== 'cart',
                'btn-success': section === 'cart',
              })}
              onClick={() => (isCartVisible.value = true)}
            >
              🛒 Кошик
            </button>
          )) ||
            null}
        </div>
      </div>
    </div>
  );
};
