import { Product } from 'cosdb-types';

export const ProductStatuses = ({ product }: { product: Product & { available: number } }) => {
  return (
    <div
      style={{
        position: 'absolute',
        left: '1rem',
        top: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
        alignItems: 'flex-start',
      }}
    >
      {!!product.salePrice && (
        <small class="d-inline-flex px-2 py-1 fw-semibold text-danger-emphasis bg-danger-subtle border border-danger-subtle rounded-2">
          {`Знижка ${Math.round(100 - (product.salePrice / product.price) * 100)}%`}
        </small>
      )}
      {product.available === 1 && (
        <small class="d-inline-flex px-2 py-1 fw-semibold text-warning-emphasis bg-warning-subtle border border-warning-subtle rounded-2">
          Закінчується
        </small>
      )}
      {product.isBestseller && (
        <small class="d-inline-flex px-2 py-1 fw-semibold text-success-emphasis bg-success-subtle border border-success-subtle rounded-2">
          Бестселлер
        </small>
      )}
    </div>
  );
};
