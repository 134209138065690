import { ProductPricesWithCta } from '@/components/product-prices-with-cta';
import { COMPANY_ID } from '@/constants';
import { cart, isCartVisible } from '@/signals/cart';
import { getProductById } from '@/signals/products';
import { removeQueryParam } from '@/utils';
import { useRouter } from 'preact-router';
import { ProductName } from '@/components/product-name';

export const ShopList = () => {
  const [route, navigate] = useRouter();
  const queryParamsList = route.matches?.productIds || '';
  const queryParamsProductIds = queryParamsList.split(',').filter((i) => i);

  if (!queryParamsProductIds.length) return null;

  const onClose = () => {
    navigate(removeQueryParam(route.url, 'productIds'));
  };

  const onAddToCart = () => {
    cart.value = queryParamsProductIds;
    isCartVisible.value = true;
    onClose();
  };

  return (
    <>
      <div class="modal-backdrop show" style={{ display: 'block', zIndex: 998 }} />
      <div class="modal" style={{ display: 'block', zIndex: 998 }} onClick={onClose}>
        <div class="modal-dialog modal-dialog-centered" onClick={(e) => e.stopPropagation()}>
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Підбірка</h4>
              <button type="button" class="btn-close" onClick={onClose} />
            </div>
            <div class="modal-body">
              <div class="list-group mb-4">
                {queryParamsProductIds.length === 0 && <i>Нічого не обрано.</i>}
                {queryParamsProductIds.map((id) => {
                  const p = getProductById(id);

                  if (!p) return null;

                  return (
                    <div class="card mb-1">
                      <div class="row g-0 align-items-center">
                        <div class="col-md-3 p-2 text-center">
                          {p.thumbnail && (
                            <img
                              style={{ maxHeight: 100 }}
                              src={`https://storage.googleapis.com/cosdb2-thumbnails/c/${COMPANY_ID}/products/${(p.thumbnail || '').split('.')[0]}_480x480.webp`}
                              class="img-fluid rounded-start"
                              alt={p.name}
                            />
                          )}
                        </div>
                        <div class="col-md-9">
                          <div class="card-body">
                            <ProductName product={p} size="h6" clickable />
                            <ProductPricesWithCta product={p} />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" onClick={onClose}>
                Закрити
              </button>
              <button type="submit" class="btn btn-primary" onClick={onAddToCart}>
                Додати все в кошик
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
