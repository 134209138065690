import { useEffect } from 'preact/hooks';
import { auth } from '@/firebase';
import { products } from '@/signals/products';
import { categories } from '@/signals/categories';
import { FilteredProducts } from '@/components/FilteredProducts';
import s from './index.module.css';
import { ShopSubheader } from './subheader';
import { ShopBrandDetails } from './brand-details';
import { brands } from '@/signals/brands';
import Router, { Route } from 'preact-router';
import { signInAnonymously } from 'firebase/auth';
import { computed } from '@preact/signals';
import { ShopCart } from './cart';
import { ProductCard } from './product-card';
import { subscribeToShopProducts } from '@/apis/subscribeToShopProducts';
import { subscribeToShopBrands } from '@/apis/subscribeToShopBrands';
import { subscribeToShopCategories } from '@/apis/subscribeToShopCategories';
import { viewport } from '@/signals/viewport';
import { ShopHeaderMobile } from './header-mobile';
import { ShopHeaderDesktop } from './header-desktop';
import { ShopList } from './list';
import { subscribeToShopOrders } from '@/apis/subscribeToShopOrders';
import { orders } from '@/signals/orders';

const isReady = computed<boolean>(() => !!(brands.value.length && categories.value.length && products.value.length));

export const Shop = ({ section, id }: { section?: string; id?: string }) => {
  useEffect(() => {
    signInAnonymously(auth)
      .then(() => {
        subscribeToShopProducts((_products) => (products.value = _products));
        subscribeToShopBrands((_brands) => (brands.value = _brands));
        subscribeToShopCategories((_categories) => (categories.value = _categories));
        subscribeToShopOrders((_orders) => (orders.value = _orders));
      })
      .catch((e) => console.warn(e));
  }, []);

  if (!isReady.value)
    return (
      <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  const filteredProductsProps =
    (section === 'brands' && { brand: id }) ||
    (section === 'types' && { type: id }) ||
    (section === 'problems' && { problem: id }) ||
    (section === 'components' && { component: id }) ||
    {};

  return (
    <div class={s.Shop}>
      {/* header */}
      <Router>
        {viewport.value === 'mobile' && <Route path="/shop/:section?/:id?" component={ShopHeaderMobile} />}
        {viewport.value !== 'mobile' && <Route path="/shop/:section?/:id?" component={ShopHeaderDesktop} />}
      </Router>

      {/* subheader */}
      <Router>
        <Route path="/shop/cart" component={() => null} />
        {viewport.value !== 'mobile' && <Route path="/shop/:section/:id?" component={ShopSubheader} />}
      </Router>

      {/* content */}
      <div className={s.Shop__content}>
        <div class="container py-4">
          <Router>
            <Route path="/shop/brands/:brand" component={ShopBrandDetails} />
          </Router>

          <FilteredProducts {...filteredProductsProps} />
        </div>

        <ProductCard />
        <ShopCart />
        <ShopList />
      </div>
    </div>
  );
};
